@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  src: local('Aeonik-Regular'), url('./assets/fonts/Aeonik-Regular.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 900;
  src: local('Aeonik-Black'), url('./assets/fonts/Aeonik-Black.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 100;
  src: local('Aeonik-Thin'), url('./assets/fonts/Aeonik-Thin.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 300;
  src: local('Aeonik-Light'), url('./assets/fonts/Aeonik-Light.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  src: local('Aeonik-Medium'), url('./assets/fonts/Aeonik-Medium.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 700;
  src: local('Aeonik-Bold'), url('./assets/fonts/Aeonik-Bold.otf') format('opentype');
}
@font-face {
  font-display: swap;
  font-family: 'Aeonik Air';
  font-style: normal;
  font-weight: 400;
  src: local('Aeonik-Air'), url('./assets/fonts/Aeonik-Air.otf') format('opentype');
}
:root {
  --black: #171717;
  --blue: #00c2ff;
  --white: #f1f2f5;
  --grey-light: #949698;
  --grey-400: #b4b6b8;
  --grey-300: #d8d9dc;
  --grey-dark: #3a3b3e;
  --black-900: #1a1b1d;
  --pink: #ffa0ea;
  --red: #eb5757;
  --purple: #6041ff;
  --purple-light: rgba(96, 65, 255, 0.2);
  --purple-300: #af71f4;
}

.logo {
  height: 2rem;
}
* {
  font-family: Aeonik, Arial, sans-serif;
}
body {
  align-items: center;
  background-color: #171717;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: space-between;
  font-family: Aeonik, Arial, sans-serif;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  gap: 1rem;
}
input {
  background: transparent;
  border: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-light);
  color: var(--white);
  padding: 0.5rem 0;
  font-size: 1.5rem;
  outline: 0;
  transition: all 0.3s ease;
  width: 100%;
}
input:focus {
  border-image-source: linear-gradient(90deg, #00c2ff, #00c2ff 0%, #6041ff 28%, #ffa0ea 49%);
  border-width: 2pt;
  border-image-slice: 1;
}
input:not(:placeholder-shown) {
  border-bottom: 2px solid var(--white);
}
input.with-error {
  margin-bottom: 0.2rem;
  border-color: var(--red);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

label {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
button,
.btn {
  align-self: center;
  background-color: var(--purple);
  border: 1px solid var(--purple);
  color: var(--white);
  cursor: pointer;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  font-weight: normal;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-top: 6rem;
}
button[type='button'] {
  background-color: var(--grey-dark);
  border: 1px solid var(--grey-dark);
}

button:hover,
.btn:hover {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}

button:disabled {
  background-color: var(--grey-dark);
  border: 1px solid var(--grey-dark);
  cursor: default;
}
form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.input-group {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
}
.input-error {
  color: var(--red);
  align-self: flex-start;
}
select {
  background: transparent;
  border: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-light);
  color: var(--white);
  cursor: pointer;
  padding: 1rem 0;
  font-size: 1.5rem;
  outline: 0;
  min-width: 20rem;
  transition: all 0.3s ease;
}
.select {
  align-self: stretch;
  color: var(--white);
}
.select__control {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--grey-light) !important;
  align-self: stretch;
}
.select__control:hover {
  box-shadow: none !important;
}
.select__menu {
  background-color: var(--black) !important;
  border: 1px solid var(--white) !important;
}
.select__option--is-focused {
  background-color: var(--grey-light) !important;
  cursor: pointer !important;
}
.select__option--is-selected {
  background-color: var(--purple) !important;
}
.select__single-value {
  color: var(--white) !important;
}
.select__multi-value {
  background-color: var(--purple) !important;
}
.select__multi-value__label {
  color: var(--white) !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 5rem;
  line-height: 4.5rem;
  margin: 0 0 2rem 0;
}

h1 .gradient {
  background: linear-gradient(92.87deg, #00c2ff 0%, #8168ff 50%, #ffa0ea 120%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h2 {
  font-size: 2rem;
  margin: 0;
  line-height: 2.5rem;
}

p {
  border-top: 1px solid var(--white);
  padding-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.react-tooltip {
  padding: 1rem !important;
  background: var(--purple) !important;
  box-sizing: border-box;
  opacity: 1 !important;
  max-width: 20rem !important;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0 0 1.5rem 0;
}
  label,
  h2 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  input,
  p {
    font-size: 1rem;
  }

  button {
    margin-top: 2.5rem;
    font-size: 0.75rem;
    padding: 0 1rem;
    line-height: 2.25rem;
    width: 100%;
  }
}
